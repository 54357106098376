<template>
  <div class="body">
    <div class="content" v-if="activated">
      加载中...
      <!-- <div class="p1">{{ content }}</div>
      <div class="id">id: {{ id }}</div>
      <div class="token">token: {{ token }}</div> -->
    </div>
  </div>
</template>

<script>
import { removeToken } from '../../utils/auth';
import { activateUser } from '@/api/index';

export default {
  name: 'emailVerify',
  components: {},
  data() {
    return {
      id: '',
      token: '',
      activated: false,
      content: '',
    };
  },
  mounted() {
    //this.acquireVerification();
    this.id = this.$route.query.id;
    this.token = this.$route.query.token;
    this.activate();
    removeToken();
  },
  methods: {
    //跳转至注册页
    toRegister() {
      this.$router.push({
        path: '/register',
      });
    },

    //登录动作接口
    activate() {
      var data = {
        userId: this.id,
        token: this.token,
      };
      activateUser(data)
        .then((response) => {
          if (!response) return;
          this.activated = true;
          this.content = 'Account activated successfully, jumping to login page...';
          setTimeout(() => {
            this.$router.push({
              path: '/login',
              query: {
                username: this.$route.query.username,
              },
            });
          }, 3000);
        })
        .catch((error) => {});
    },
  },
};
</script>

<style lang="less" scoped>
@mainBlue: rgb(47, 63, 80);
.body {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}
.content {
  position: relative;
  //   background-color: antiquewhite;
  background-color: #e5e5e5;
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  .p1 {
    font-size: 25px;
    font-weight: 700;
    color: @mainBlue;
  }
  .id {
    font-size: 20px;
    font-weight: 700;
    color: @mainBlue;
  }
  .token {
    font-size: 20px;
    font-weight: 700;
    color: @mainBlue;
  }
}
</style>
